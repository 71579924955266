<template>
    <v-container class="mt-4 text-left">
        <div v-if="!loading">
            <v-row>
                <v-col cols="12" md="6">
                    <v-form v-model="valid" ref="form" lazy-validation>
                        <div class="subtitle-1 primary--text">{{ 'Contact information' | localize }}</div>
                        <v-row class="mt-n2">
                            <v-col cols="12" sm="6">
                                <v-text-field dense color="primary" 
                                    :label="'First name' | localize" 
                                    outlined
                                    v-model="firstName">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field dense color="primary"
                                    :label="'Last name' | localize"
                                    outlined v-model="lastName"
                                    :rules="[rules.required]">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="mt-n4">
                            <v-col cols="12" >
                                <v-text-field dense
                                    color="primary"
                                    :label="'Email' | localize"
                                    outlined
                                    v-model="email"
                                    :rules="[rules.required, rules.email]">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="mt-n4">
                            <v-col>
                                <v-text-field dense 
                                    :label="'Phone' | localize" 
                                    outlined color="primary" 
                                    placeholder="+373 625-820-54" 
                                    v-model="phone"
                                    :rules="[rules.required, rules.phone]">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <div class="subtitle-1 primary--text">{{'Shipping address' | localize}}</div>
                        <v-row class="mt-n2">
                            <v-col>
                                <v-select dense 
                                    outlined
                                    :menu-props="{ top: true, offsetY: true }"
                                    :label="'Country' | localize"
                                    v-model="country"
                                    :items="items"
                                    :rules="[rules.required]">
                                </v-select>
                            </v-col>
                            <v-col>
                                <v-text-field dense outlined
                                    :label="'City' | localize" 
                                    v-model="city"
                                    :rules="[rules.required]">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="mt-n4">
                            <v-col>
                                <v-text-field dense color="primary"
                                :label="'Address' | localize" 
                                outlined 
                                v-model="address"
                                :rules="[rules.required]">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <div class="subtitle-1 primary--text">{{'Additional information' | localize }}</div>
                        <v-row class="mt-n2">
                            <v-col>
                                <v-textarea
                                    outlined 
                                    :label="'comments' | localize"
                                    auto-grow
                                    rows="2"
                                    row-height="8"
                                    v-model="comments">
                                </v-textarea>
                            </v-col>
                        </v-row>
                        <v-row class="mt-n4">
                            <v-checkbox
                                v-model="agree"
                                :label="'I agree to the processing of personal data' | localize"
                                color="primary"
                                hide-details
                            ></v-checkbox>
                        </v-row>
                        <v-row>
                            <v-btn class="ml-auto mr-2 mt-2 text-lowercase" 
                                color="primary" dark 
                                @click="confirmOrder()">
                                {{'confirm' | localize }} 
                            </v-btn>
                        </v-row>
                    </v-form>
                </v-col>
                <v-col cols="12" md="6">
                    <div v-if="productsInCart.length">
                        <v-container>
                            <v-card flat
                                class="overflow-y-auto pa-4"
                                max-height="250">
                                <v-row v-for="prod, i in productsInCart" :key="i">
                                    <v-col>
                                        <v-img :src="prod.images[0].url" width="90" contain>
                                        </v-img>
                                    </v-col>
                                    <v-col class="caption">{{ localName(prod)}}</v-col>
                                    <v-col class="body-2">{{ getPrice(prod).toLocaleString('en-US') }} x {{ prod.numbers }} = {{ suma(prod).toLocaleString('en-US') }} {{ currency }}</v-col>
                                </v-row>
                            </v-card>
                            
                            <v-divider class="my-2"></v-divider>

                            <v-row justify="start" class="subtitle-2 mt-4 pl-12">{{ 'Total in cart' | localize }}: {{ totalInCart().toLocaleString('en-US') }} {{currency}}</v-row>
                            
                            <!-- discount -->
                            <div v-if="discount() > 0">
                                <v-row justify="start" class="subtitle-2 mt-4 pl-12">
                                    {{ 'Discount' | localize }} ({{ action.discount }} %): {{ discount().toFixed(2) }} MDL    
                                </v-row>
                                <v-row justify="start" class="subtitle-2 mt-4 pl-12">
                                    {{ 'Total with discount' | localize }}: {{ Number((totalInCart() - discount()).toFixed(2)).toLocaleString('en-US') }} MDL
                                </v-row>
                            </div>
                            
                            <!-- delivery -->
                            <v-row v-if="this.country == ''" justify="start" class="subtitle-2 mt-4 pl-12">
                                {{ 'Delivery' | localize }}: - 
                            </v-row>
                            <div v-else>
                                <v-row v-if="this.country == 'Romania' || this.country == 'Румыния'"
                                    justify="start" class="subtitle-2 mt-4 error--text pl-12">
                                    {{ error }}
                                </v-row>
                                <v-row v-else justify="start" class="subtitle-2 mt-4 pl-12">
                                    {{ 'Delivery' | localize }}: {{ delivery() }} MDL
                                </v-row>
                            </div>
                                                        
                            <!--total summa-->
                            <div v-if="currency == 'MDL'">
                                <v-row justify="start" class="title mt-4 pl-12">
                                    {{ 'Total suma' | localize }}: {{ Number(totalSuma().toFixed(2)).toLocaleString('en-US') }} {{ currency }}
                                </v-row>
                                <v-row justify="start" class="subtitle-2 mt-4 pl-12">
                                    {{ 'including VAT' | localize }}: {{ Number((totalSuma() * 0.2).toFixed(2)).toLocaleString('en-US') }} {{ currency }}
                                </v-row>
                            </div>
                            <!--delivery conductions card-->
                            <v-row justify="end" class="mt-4 mr-n12">
                                <v-card class="pa-4"
                                    max-width="320">
                                    <v-card-text class="text-left">
                                        <v-row class="primary--text text-subtitle-2">
                                            <v-icon color="primary" class="mr-2">mdi-truck</v-icon>
                                            {{ 'Order delivery conditions' | localize }}:
                                        </v-row>
                                        <v-row class="text-subtitle-2">
                                            ~ {{ 'in Chisinau' | localize }}:
                                        </v-row>  
                                        <v-row class="text-body-2">
                                            {{ 'up to' | localize }} {{ delivery_free }} MDL - {{ delivery_chisinau }} MDL
                                        </v-row>
                                        <v-row class="text-body-2">
                                            {{ 'more than' | localize }} {{ delivery_free }} MDL - 0 MDL
                                        </v-row>   
                                        <v-row class="text-subtitle-2">
                                            ~ {{ 'outside Chisinau (within Moldova)' | localize }}:
                                        </v-row>
                                        <v-row class="text-body-2">
                                            {{ 'postal delivery' | localize }} - {{ delivery_moldova }} MDL
                                        </v-row>
                                        <v-row class="text-subtitle-2">
                                            ~ {{ 'delivery outside Moldova' | localize }}:
                                        </v-row>
                                        <v-row class="text-body-2">
                                            {{ 'please call for details' | localize }}: +373 78 679 888
                                        </v-row>
                                    </v-card-text>
                                    
                                </v-card>
                            </v-row>
                        </v-container> 
                    </div>
                </v-col>
            </v-row>
        </div>
        <v-row v-else justify="center">
            <loader></loader>
        </v-row>
    </v-container>
</template>
<script>
import localizeFilter from '../filters/localize.filter';
import Loader from '../layouts/Loader.vue';

export default {
    data() {
        return {
            valid: false,
            error: '',
            firstName: '',
            lastName: '',
            email: '',
            city: '',
            country: '',
            address: '',
            phone: '',
            comments: '',
            agree: false,
            chisinau_dic: ['Chisinau', 'chisinau', 'Chișinău', 'chișinău', 'Chisinău', 'chisinău', 'Кишинэу', 'кишинэу', 'Кишинев', 'кишинев', 'Кишинёв', 'кишинёв', 'Kishinev', 'kishinev', 'Kishinau', 'kishinau'],
            rules: {
                required: value => !!value || localizeFilter('required field'),
                phone: value => { 
                    const pattern = /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/
                    return pattern.test(value) || localizeFilter('invalid phone')
                },
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || localizeFilter('invalid e-mail')
                }
            },
            /*delivery prices*/
            delivery_chisinau: 50,
            delivery_moldova: 70,
            delivery_free: 500,
        }
    },
    computed: {
        currency() {
            return this.$store.getters.currency;
        },
        locale() {
            return this.$store.getters.locale;
        },
        loading() {
            return this.$store.getters.loading;
        },
        productsInCart() {
            return this.$store.getters.productsInCart;
        },
        userData() {
            return this.$store.getters.userData;
        },
        items() {
            return [localizeFilter('Moldova'), localizeFilter('Romania')]
        },
        action() {
            return this.$store.getters.action;
        }
    },
    watch: {
        country() {
            if (this.country == 'Moldova' || this.country == 'Молдова') {
                this.$store.dispatch('setCurrency', 'MDL');
            }
        }
    },
    methods: {
        localName(item) {
            return item[`name_${this.locale.split('-')[0]}`]
        },
        getPrice(item) {
            if (this.currency === 'MDL') {
                return item.price;
            }
            const key = `price_${this.currency}`;
            return item[key];
        },
        suma(prod) {
            if (this.currency === 'MDL') {
                return prod.price * prod.numbers;
            }
            return prod[`price_${this.currency}`] * prod.numbers;
        },
        totalInCart() {
            let res = 0;
            if (this.currency === 'MDL') {
                res = this.productsInCart.reduce((summ, prod) => {
                    return summ + parseInt(prod.price * prod.numbers)
                }, 0)
            }
            else {
                res = this.productsInCart.reduce((summ, prod) => {
                    return summ + parseInt(prod[`price_${this.currency}`] * prod.numbers)
                }, 0)
            }
            return res
        },
        delivery() {
            let sum = 0
            const city = this.chisinau_dic.includes(this.city);
            if (this.country == 'Moldova' || this.country == 'Молдова') {
                if (city) {
                    this.totalInCart() < this.delivery_free ? sum = this.delivery_chisinau : sum = 0
                }
                else {
                    sum = this.delivery_moldova
                }
            }
            else if (this.country == 'Romania' || this.country == 'Румыния') {
                this.error = localizeFilter('to delivery outside Moldova, please call for details') + ': +373 78 679 888';
                sum = 0;
            }
            return sum
        },
        discount() {
            let sum = 0;
         
            if (this.currency == 'MDL' && this.action?.discount) {
                sum = this.totalInCart() * this.action.discount / 100;
            }
            return sum;
        },
        totalSuma() {
            return this.totalInCart() + this.delivery() - this.discount()
        },
        resetData() {
            this.firstName = '';
            this.lastName = '';
            this.email = '';
            this.phone = '';
            this.country = '';
            this.city = '';
            this.address = '';
            this.comments = '';
            this.valid = false;
            this.agree = false;
        },
        isEmpty(obj) {
            for (var x in obj) {
                return false;
            }
            return true;
        },
        async confirmOrder() {
            const order = {};
            if (this.productsInCart.length < 1) {
               await this.$store.dispatch('setError', 'Your cart is empty!') 
               return 
            }

            if (!this.agree) {
                await this.$store.dispatch('setError', 'please, allow the personal data processing')
                return
            }

            if (this.$refs.form.validate()) {
                order.userFirstName = this.firstName;
                order.userLastName = this.lastName;
                order.userEmail = this.email;
                order.userPhone = this.phone;
                order.country = this.country;
                order.city = this.city;
                order.address = this.address;
                order.comments = this.comments;
                order.currency = this.currency;
                order.status = 'получен';
                order.delivery = this.delivery();
                order.discount = this.discount();
                //user id
                this.isEmpty(this.userData)  
                    ? order.userId = 'unauthorized user' 
                    : order.userId = this.userData.userId;

                order.products = [];//this.productsInCart;

                this.productsInCart.map(async(item) => {
                    const prod = {
                        id: item.id,
                        name: item.name_en,
                        price: this.getPrice(item),
                        quantity: item.numbers
                    }
                    order.products.push(prod);
                    //clear cart
                    await this.$store.dispatch('removeProductFromCart', item.id);
                    await this.$store.dispatch('clearMessage');
                });
                
                try {
                    //to do sent to db
                    await this.$store.dispatch('addOrder', order);
                    //remove cart from LS
                    localStorage.removeItem('cart');

                    //to do show snackbar message 
                    await this.$store.dispatch('setError', "Thank you for your order. We'll contact you during 2 days")
                    //sent email to user

                }
                catch(error) {
                    //to do alert error 
                    await this.$store.dispatch('setError', "Something run wrong. Your order don't sent")
                }
            }
        },
    },
    async mounted() {
        await this.$store.dispatch('fetchAction');
        if (this.userData !== null) {
            this.userData.firstName ? 
                this.firstName = this.userData.firstName :
                this.firstName = '';
            
                this.userData.lastName ? 
                this.lastName = this.userData.lastName :
                this.lastName = '';

            this.userData.email ? 
                this.email = this.userData.email :
                this.email = '';

            this.userData.phone ? 
                this.phone = this.userData.phone :
                this.phone = '';
            
            this.userData.city ?
               this.city = this.userData.city :
               this.city = localizeFilter('Chisinau');
            
            this.userData.address ?
               this.address = this.userData.address :
               this.address = '';
            
            if (!this.userData.country || this.userData.country == 'Moldova' || this.userData.country == 'Молдова') {
                this.country = localizeFilter('Moldova') 
            }
            else {
               this.country = localizeFilter('Romania') 
            }
        }
    },
    components: { Loader }
}
</script>